var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.data.length
        ? _c("a-list", {
            staticClass: "demo-loadmore-list",
            attrs: { itemLayout: "horizontal", dataSource: _vm.data },
            scopedSlots: _vm._u(
              [
                {
                  key: "renderItem",
                  fn: function(item, index) {
                    return _c(
                      "a-list-item",
                      {
                        staticStyle: { color: "#000" },
                        on: {
                          click: function($event) {
                            return _vm.showMessage(item)
                          }
                        }
                      },
                      [
                        _c(
                          "a-list-item-meta",
                          [
                            _c(
                              "p",
                              {
                                class: {
                                  black: _vm.isBoiteReception && !item.read_at
                                },
                                attrs: { slot: "description" },
                                slot: "description"
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.getObject(item.subject)) +
                                    "\n        "
                                )
                              ]
                            ),
                            _c("a-avatar", {
                              attrs: {
                                slot: "avatar",
                                src:
                                  "https://www.shareicon.net/data/512x512/2016/10/20/846461_blue_512x512.png"
                              },
                              slot: "avatar"
                            }),
                            _vm.isAdmin
                              ? _c(
                                  "p",
                                  {
                                    class: {
                                      black:
                                        _vm.isBoiteReception && !item.read_at
                                    },
                                    attrs: { slot: "title" },
                                    slot: "title"
                                  },
                                  [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(
                                          _vm.isBoiteReception
                                            ? item.user.nom_fr +
                                                " " +
                                                item.user.prenom_fr
                                            : item.destination
                                        ) +
                                        "\n        "
                                    )
                                  ]
                                )
                              : _c(
                                  "p",
                                  {
                                    class: { black: !item.read_at },
                                    attrs: { slot: "title" },
                                    slot: "title"
                                  },
                                  [_vm._v("\n          SOR\n        ")]
                                )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            class: {
                              black: !item.read_at && _vm.isBoiteReception,
                              bold: !item.read_at && _vm.isBoiteReception
                            }
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.getDate(item.sent_at)) +
                                "\n      "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  }
                }
              ],
              null,
              false,
              2920350365
            )
          })
        : _c("a-empty"),
      _vm.data.length
        ? _c("a-pagination", {
            staticStyle: { "margin-top": "1%" },
            attrs: {
              current: _vm.pagination.current,
              total: _vm.pagination.total,
              showTotal: function(total) {
                return "Total " + total
              },
              pageSize: _vm.pagination.pageSize,
              showSizeChanger: true
            },
            on: {
              change: _vm.onPaginationChange,
              showSizeChange: _vm.onPageSizeChange
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }