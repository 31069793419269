<template>
  <div>
    <a-list
      class="demo-loadmore-list"
      itemLayout="horizontal"
      v-if="data.length"
      :dataSource="data"
    >
      <a-list-item
        slot="renderItem"
        slot-scope="item, index"
        v-on:click="showMessage(item)"
        style="color: #000;"
      >
        <a-list-item-meta>
          <p
            slot="description"
            v-bind:class="{ black: isBoiteReception && !item.read_at }"
          >
            {{ getObject(item.subject) }}
          </p>
          <a-avatar
            slot="avatar"
            src="https://www.shareicon.net/data/512x512/2016/10/20/846461_blue_512x512.png"
          />
          <p
            slot="title"
            v-if="isAdmin"
            v-bind:class="{ black: isBoiteReception && !item.read_at }"
          >
            {{
              isBoiteReception
                ? item.user.nom_fr + " " + item.user.prenom_fr
                : item.destination
            }}
          </p>
          <p slot="title" v-else v-bind:class="{ black: !item.read_at }">
            SOR
          </p>
        </a-list-item-meta>
        <div
          v-bind:class="{
            black: !item.read_at && isBoiteReception,
            bold: !item.read_at && isBoiteReception
          }"
        >
          {{ getDate(item.sent_at) }}
        </div>
      </a-list-item>
    </a-list>

    <a-empty v-else />

    <a-pagination
      v-if="data.length"
      style="margin-top:1%"
      @change="onPaginationChange"
      @showSizeChange="onPageSizeChange"
      :current="pagination.current"
      :total="pagination.total"
      :showTotal="total => `Total ${total}`"
      :pageSize="pagination.pageSize"
      :showSizeChanger="true"
    />
  </div>
</template>
<script>
import moment from "moment";

const IS_BOITE_RECEPTION = 0;
// const SHOW_MSG_URL = null;

export default {
  name: "EmailsList",
  created() {},
  data() {
    return {
      local_pagination: {
        onChange: page => {
          this.$emit("change", page);
        },
        onShowSizeChange: (current, size) => {
          this.$emit("show_size_change", size);
        },
        current: this.pagination.current,
        pageSize: this.pagination.pageSize,
        showSizeChanger: true,
        total: this.pagination.total,
        showTotal: () => {
          `Total ${this.pagination.total}`;
        }
      },
      MSG_READ: 1,
      IS_BOITE_RECEPTION
    };
  },
  computed: {},
  props: {
    data: Array,
    pagination: Object,
    isAdmin: Boolean,
    isBoiteReception: Boolean
  },
  methods: {
    moment,
    onPaginationChange(page) {
      this.$emit("change", page);
    },
    onPageSizeChange(current, size) {
      this.$emit("show_size_change", size);
    },
    showMessage(item) {
      let IS_ADMIN = 0;
      let TO = "show_email_adherant";
      if (this.isAdmin) {
        IS_ADMIN = 1;
        TO = "show_email_admin";
      }
      let params = { name: TO, params: { id: item.id, isAdmin: IS_ADMIN } };
      this.$router.push(params);
    },
    getDate(dt) {
      return this.date(dt, "DD MMM YY HH:mm");
    },
    getObject(text) {
      return text.length > 80 ? text.substr(0, 80) + "..." : text;
    }
  }
};
</script>
<style>
.demo-loadmore-list {
  min-height: 350px;
}

.black {
  color: black !important;
}

.bold {
  font-weight: 900;
}

.ant-list-item {
  cursor: pointer !important;
  padding: 0px;
}
</style>
